import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { NotFoundComponent } from './pages/not-found/not-found.component'
import { PasswordResetComponent } from './pages/password-reset/password-reset.component'
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component'

const routes: Routes = [
  {
    path: '',
    component: NotFoundComponent
  },
  {
    path: 'reset-password/:token',
    component: PasswordResetComponent
  },
  {
    path: 'verify-email/:token',
    component: VerifyEmailComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
