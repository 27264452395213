import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { AuthService } from '../../services/auth.service'

type Status = 'pending' | 'loading' | 'invalid' | 'valid'

@Component({
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyEmailComponent implements OnInit {
  result$ = new BehaviorSubject<Status>('pending')

  constructor (
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit () {
    this.activatedRoute.paramMap
      .pipe(
        map((params) => params.get('token')),
        switchMap((token) => {
          return this.checkToken(token || undefined)
        })
      )
      .subscribe((valid) => {
        if (valid) {
          this.result$.next('valid')
        } else {
          this.result$.next('invalid')
        }
      })
  }

  checkToken (token?: string) {
    this.result$.next('loading')

    if (!token) {
      this.result$.next('invalid')
      return of(false)
    }

    return of(token).pipe(
      switchMap((token) => {
        return this.authService.checkVerificationToken(token)
      }),
      catchError((error) => {
        console.log('error', error)
        return of(false)
      })
    )
  }
}
