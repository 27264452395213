import { HttpClient, HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'

import { API_URL } from '../injection-tokens/api-url.injection-token'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor (private http: HttpClient, @Inject(API_URL) private apiUrl: string) {}

  public checkToken (token: string): Observable<boolean> {
    const params = new HttpParams().append('token', token)
    return this.http.get(`${this.apiUrl}/user/reset-password`, { params, observe: 'response' }).pipe(
      switchMap((response) => {
        if (response.status === 200) {
          return of(true)
        } else {
          return of(false)
        }
      }),
      catchError(() => {
        return of(false)
      })
    )
  }

  public checkVerificationToken (token: string): Observable<boolean> {
    return this.http.patch(`${this.apiUrl}/user/verify-email`, { token }, { observe: 'response' }).pipe(
      switchMap((response) => {
        if (response.status === 200) {
          return of(true)
        } else {
          return of(false)
        }
      }),
      catchError(() => {
        return of(false)
      })
    )
  }

  public setPassword (token: string, password: string): Observable<unknown> {
    return this.http.patch(`${this.apiUrl}/user/reset-password`, { token, password })
  }
}
