import { InjectionToken } from '@angular/core'

export function checkApiUrl (url: string) {
  let apiUrl = url.trim()

  if (apiUrl.endsWith('/')) {
    apiUrl = apiUrl.substring(0, apiUrl.length - 1)
  }

  return apiUrl
}

export const API_URL = new InjectionToken('API_URL')
