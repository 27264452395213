<div class="page justify-center items-center">
  <wzf-image-box>
    <form id="pw-reset-form" class="text-center" (ngSubmit)="submit()" [formGroup]="form">
      <h4 class="text-center" *ngIf="(formState$ | async) !== 'finished'">Passwort zurücksetzen</h4>

      <div *ngIf="(formState$ | async) === 'checkingToken'" class="checking-token-state">
        <mat-spinner></mat-spinner>
      </div>

      <ng-container *ngIf="(formState$ | async) === 'tokenValid'">
        <mat-form-field appearance="fill">
          <mat-label>Passwort</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required />
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="form.get('password')?.invalid">{{ getErrorMessage('password') }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Passwort wiederholen</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="passwordRepeat" required />
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="form.get('passwordRepeat')?.invalid">{{ getErrorMessage('passwordRepeat') }}</mat-error>
        </mat-form-field>

        <mat-error class="password-match-error" *ngIf="form.hasError('passwordNotMatching')"
          >Passwörter stimmen nicht überein
        </mat-error>

        <button
          type="submit"
          mat-flat-button
          [disabled]="(loading$ | async) || form.invalid"
          color="primary"
          [style.margin-top]="'auto'"
        >
          Passwort setzen
          <mat-icon *ngIf="loading$ | async">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </button>
      </ng-container>

      <ng-container *ngIf="(formState$ | async) === 'tokenInvalid'">
        <h3 class="text-center">Ungültiger Token</h3>
      </ng-container>

      <ng-container *ngIf="(formState$ | async) === 'finished'">
        <div class="justify-center h-full w-full text-center flex-col">
          <mat-icon class="success-icon">check_circle</mat-icon>
          <h4>Passwort erfolgreich gespeichert!</h4>
        </div>
      </ng-container>
    </form>
  </wzf-image-box>
</div>
