import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ImageBoxComponent } from './components/image-box/image-box.component'
import { API_URL, checkApiUrl } from './injection-tokens/api-url.injection-token'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { PasswordResetComponent } from './pages/password-reset/password-reset.component'
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component'

@NgModule({
  declarations: [AppComponent, PasswordResetComponent, VerifyEmailComponent, NotFoundComponent, ImageBoxComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],
  providers: [
    {
      provide: API_URL,
      useValue: checkApiUrl(environment.apiUrl)
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
