import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject, Subject } from 'rxjs'
import { finalize, takeUntil } from 'rxjs/operators'

import { AuthService } from '../../services/auth.service'

export type FormState = 'checkingToken' | 'tokenInvalid' | 'tokenValid' | 'finished'

@Component({
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordResetComponent implements OnDestroy {
  public loading$ = new BehaviorSubject(false)
  public formState$ = new BehaviorSubject<FormState>('checkingToken')
  private destroy$ = new Subject()
  public hide = true
  public form: FormGroup
  private token: string
  public error: Error | null = null

  constructor (public authService: AuthService, private route: ActivatedRoute, private fb: FormBuilder) {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('token')) {
        const token = params.get('token')
        this.token = String(token)
        this.checkToken(token as string)
      }
    })

    this.form = this.fb.group(
      {
        password: [null, [Validators.required, Validators.minLength(6)]],
        passwordRepeat: [null, [Validators.required, Validators.minLength(6)]]
      },
      { validators: this.checkPasswords }
    )

    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      state ? this.form.disable() : this.form.enable()
    })
  }

  ngOnDestroy () {
    this.destroy$.next()
    this.destroy$.complete()
  }

  getErrorMessage (formControlName: string) {
    if (this.form.get(formControlName)?.hasError('minlength')) {
      return 'Mindestens 6 Zeichen'
    }
  }

  submit () {
    console.log(this.form)
    if (this.form.valid) {
      const formValue = this.form.value
      this.loading$.next(true)
      this.error = null
      this.authService
        .setPassword(this.token, formValue.password)
        .pipe(finalize(() => this.loading$.next(false)))
        .subscribe(
          () => this.formState$.next('finished'),
          (error) => {
            this.error = error
            window.confirm(error?.error?.message || error)
          }
        )
    }
  }

  private checkToken (token: string) {
    this.loading$.next(true)
    this.formState$.next('checkingToken')
    this.authService
      .checkToken(token)
      .pipe(
        finalize(() => {
          this.loading$.next(false)
        })
      )
      .subscribe(
        (response) => response ? this.formState$.next('tokenValid') : this.formState$.next('tokenInvalid'),
        () => this.formState$.next('tokenInvalid')
      )
  }

  private checkPasswords (group: FormGroup) {
    const password = group.get('password')?.value
    const passwordRepeat = group.get('passwordRepeat')?.value

    return password === passwordRepeat ? null : { passwordNotMatching: true }
  }
}
