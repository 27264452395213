<div class="page justify-center items-center">
  <wzf-image-box>
    <div class="text-center">
      <h4 class="text-center mb-4">E-Mail verifizieren</h4>

      <ng-container *ngIf="result$ | async as status">
        <ng-container [ngSwitch]="status">
          <div *ngSwitchCase="'pending'">Bitte laden Sie diese Seite neu</div>
          <div *ngSwitchCase="'invalid'">
            <div class="mb-3 text-red-400">
              <mat-icon class="scale-150">error</mat-icon>
            </div>

            <h3 class="mt-0">Leider ist bei der Verifizierung deiner E-Mail Adresse ein Fehler aufgetreten.</h3>
          </div>
          <div *ngSwitchCase="'valid'">
            <div class="mb-3 text-green-400">
              <mat-icon class="scale-150">check_circle</mat-icon>
            </div>

            <h3 class="mt-0">Deine E-Mail Adresse wurde erfolgreich verifiziert!</h3>
          </div>
          <div *ngSwitchCase="'loading'">
            <mat-spinner class="inline-block" diameter="32"></mat-spinner>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </wzf-image-box>
</div>
