<div class="page justify-center items-center">
  <mat-card class="p-0">
    <div class="flex flex-col sm:flex-row">
      <img class="max-w-full w-80" src="assets/logo/wzf_logo.jpg" />

      <div class="p-4 w-72">
        <ng-content></ng-content>
      </div>
    </div>
  </mat-card>
</div>
